import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LeadService } from 'src/app/core/services/lead/lead.service';
import { ProductService } from 'src/app/core/services/product/product.service';
import { SnackbarService } from 'src/app/core/utils/snackbar/snackbar.service';

@Component({
  selector: 'app-add-senders',
  templateUrl: './add-senders.component.html',
  styleUrls: ['./add-senders.component.scss']
})
export class AddSendersComponent implements OnInit {
  addForm;
  edit;
  constructor(
    private productService: ProductService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddSendersComponent>,
    private leadService: LeadService,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.createForm();
    console.log(this.data)
    if (this.data['key']) {
      this.edit = true;
      this.fillForm(this.data);
    }
  }

  createForm(): void {
    this.addForm = this.fb.group({
      key: ["senderId", Validators.required],
      value: this.fb.array([
        this.fb.group({
          key: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
          isDisabled: [false],
          isApproved: [false]
        })
      ])
    });
  }


  addNewSender(data?) {
    let control = <FormArray>this.addForm.controls.value;
    control.push(
      this.fb.group({
        key: [data ? data.key : '', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
        isDisabled: [data ? data.isDisabled : false],
        isApproved: [data ? data.isApproved : false],
        isNew: [data ? false : true]
      })
    );
  }
  deleteSender(index) {
    let control = <FormArray>this.addForm.controls.value;
    control.removeAt(index);
  }


  addSender(formData) {
    this.leadService.startLoading();
    let data = { ...formData };
    data.value = formData.value.map(element => {
      return {
        [element.key]: {
          isDisabled: element.isDisabled,
          isApproved: element.isApproved,
          isNew: element.isNew
        }
      }
    })

    if (this.edit) {
      this.productService.editConstant(this.data._id, data).subscribe(
        (res: any) => {
          this.snackBar.openSnackBar(res["message"]);
          this.addForm.reset();
          this.leadService.stopLoading();
          this.dialogRef.close();
        },
        (err) => {
          this.leadService.stopLoading();
          this.snackBar.openSnackBar(err);
        }
      );
    }
    else {
      this.productService.addConstant(data).subscribe(
        (res: any) => {
          this.snackBar.openSnackBar('Thank you, please wait 48 hours for us to approve from backend');
          this.addForm.reset();
          this.leadService.stopLoading();
          this.dialogRef.close();
        },
        (err) => {
          this.leadService.stopLoading();
          this.snackBar.openSnackBar(err);
        }
      );
    }
  }

  fillForm(data) {
    this.addForm.controls.key.setValue(data.key);
    if (data.value && data.value.length > 0) {
      let control = this.addForm.controls.value;
      control.removeAt(0);
      data.value.forEach(element => {
        let key = Object.keys(element)[0];
        let senderData = {
          isDisabled: element[key].isDisabled,
          isApproved: element[key].isApproved,
          key: key
        }
        this.addNewSender(senderData);
      });
    }
    // this.addForm.controls.value.setValue(data.value);
  }

}
