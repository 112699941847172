// import { environment } from 'src/environments/environment';
// import { AccountService } from './../_services/account.service';
// declare var FB;
// export function appInitializer(accountService: AccountService) {
//     return () => new Promise(resolve => {
//         // wait for facebook sdk to initialize before starting the angular app
//         window['fbAsyncInit'] = function () {
//             FB.init({
//                 appId: environment.facebookAppId,
//                 cookie: true,
//                 xfbml: true,
//                 version: 'v8.0'
//             });
//             // auto authenticate with the api if already logged in with facebook
//             if(accountService){
//                 FB.getLoginStatus(({authResponse}) => {
//                     if (authResponse) {
//                         accountService.apiAuthenticate(authResponse.accessToken)
//                             .subscribe()
//                             .add(resolve);
//                     } else {
//                         resolve(null);
//                     }
//                 });
//             }
//             else{
//                 resolve(null)
//             }
//         };

//         // load facebook sdk script
//         (function (d, s, id) {
//             var js, fjs = d.getElementsByTagName(s)[0];
//             if (d.getElementById(id)) { return; }
//             js = d.createElement(s); js.id = id;
//             js.src = "https://connect.facebook.net/en_US/sdk.js";
//             fjs.parentNode.insertBefore(js, fjs);
//         }(document, 'script', 'facebook-jssdk'));    
//     });
// }



// facebook-sdk.service.ts
import { Injectable } from '@angular/core';

declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookSdkService {
  private sdkInitialized = false;

  initializeFacebookSDK(appId: string, fbisTrue: boolean): Promise<void> {
    return new Promise((resolve) => {
      if (this.sdkInitialized) {
        FB.init({
          appId: appId,
          cookie: true,
          xfbml: true,
          version: 'v8.0'
        });
        resolve();
      } else {
        (window as any).fbAsyncInit = () => {
          FB.init({
            appId: appId,
            cookie: true,
            xfbml: true,
            version: 'v8.0'
          });
          this.sdkInitialized = true;
          resolve();
        };

        // Load the SDK script
        ((d, s, id) => {
          let js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      }
    });
  }
}
